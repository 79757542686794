body {
    margin: 0;
    min-height: 100vh;
    box-sizing: border-box;
}
img {
    object-fit: cover;
}
#root {
    min-height: 100vh;
}
::-webkit-scrollbar {
  width: 5px;
}
.big-z-index {
  z-index: 9999999;
}
.position-absolute{
  position: absolute!important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #aaa; 
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ccc; 
}

.stylesAlert {
  height: 10%;
}

iframe {
  display: none;
}

/* @media (max-width: 1368px) {
  * {
    zoom: 0.975;
  }
} */

.contextMenu {
  background-color: #ffffff;
  z-index: 9999;
  width: 150px;
}

.menuEntry {
  cursor: pointer;
  background-color: #ffffff;
  border-radius: 4px;
  border: solid 1px #000
}
.menuEntry p {
  padding: 8px
}

.menuEntry text {
  font-size: 12px;
}
.menuEntry:hover {
  background-color: #ccc;
}